import Vue from 'vue';
import Vuetify from 'vuetify/lib';
// import 'vuetify/src/stylus/app.styl'
// import '@/sass/variables.scss'
import '@/sass/style.scss'

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
      themes: {
        light: {
          primary: '#2ba9fc', //ฟ้าหลัก
          secondary: '#424242', //เทาเข้ม
          accent: '#82B1FF', //ฟ้าอ่อน
          error: '#ff6961', //แดง
          info: '#2196F3', //ฟ้า
          success: '#49aa1c', //เขียว
          //warning: '#FFC107', //เหลือง
          dark: '#666666', //เทากลาง
          light: '#f5f6fa', //เทาขาววว
          green: '#7ab40e',
          thistle: '#D8BFD8',
          red: 'ff6961', //แดงพาสเทล
        },
      },
    },

});
