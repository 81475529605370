<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text">
      ยืนยันลบข้อมูล
    </v-card-title>
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="10">
          <v-text-field
            class="pt-2"
            name="note"
            v-model="item.note"
            label="หมายเหตุ"
            outlined
            dense
            background-color="white"
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row v-if="alert" justify="center" class="mt-0">
        <p class="error--text">กรุณาใส่หมายเหตุ</p>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer/>
        <v-btn
          outlined
          class="px-10 mr-12 ml-9"
          color="primary"
          @click="save()"
        >
          Confirm
        </v-btn>
        <v-btn
          outlined
          class="px-10 mr-10"
          color="grey"
          @click="close()"
        >
          Cancel
        </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: '',
      alert: false,
    };
  },
  created(){
    console.log('DocumentDeleteWithNote')
    console.log(this.item)
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    save() {
      // this.$v.form.$touch();
      // if (this.$v.form.$error) return;
      console.log(this.item)
      if(this.item.note==''){
        this.alert = true
      }else{
        this.$emit('onSubmit', this.item);
      }
    },
  },
};
</script>
