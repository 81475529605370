<template>
    <div class="d-flex align-center pa-5">
        <div class="grey--text mr-5">Item per page</div>
        <div style="width: 80px;">
            <v-select color="primary" dense hide-details :items="list" v-model="itemsPerPage" @change="$emit('update:itemsPerPage', itemsPerPage)"/>
        </div>
        <v-spacer/>
        <v-pagination v-model="page" @input="$emit('update:page', page)" :length="length"></v-pagination>
    </div>
</template>

<script>
export default {
    props: {
        length: {
            type: Number,
            default: 0
        },
        page: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            itemsPerPage: 20,
            list: [10, 20, 50, 80, 110, 160, 200],
        }
    }
}

// <div class="d-flex align-center pa-5">
// <div class="grey--text mr-5">Item per page</div>
// <div style="width: 80px;">
// <v-select color="primary" dense hide-details :items="[2,5,10,20,50]" v-model="itemsPerPage"></v-select>
// </div>
// <v-spacer/>
// <v-pagination v-model="page" :length="pageCount"></v-pagination>
// </div>
</script>