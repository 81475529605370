<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  // created() {
  //   window.addEventListener('storage', this.handleStorageChange);
  //   console.log("Storage Work")
  // },
  // beforeDestroy() {
  //   window.removeEventListener('storage', this.handleStorageChange);
  //   console.log("Storage Delete")
  // },
  // methods: {
  //   handleStorageChange(event) {
  //     if (event.key === 'access_token') {
  //       console.error('access_token changed:', event);
  //       // Assuming Sentry is configured globally
  //       this.$sentry.captureMessage(`access_token changed: ${event.oldValue} -> ${event.newValue}`);
  //     }
  //   }
  // }
}
</script>