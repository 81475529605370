import Vue from 'vue'
import Vuex from 'vuex'
import './../plugins/axios';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        selected_branch: {
            _id: "",
            branch_name: '',
            phone_number: '',
        },
        list: {
            branchs: [],
            provinces: [
                "กระบี่",
                "กรุงเทพมหานคร",
                "กาญจนบุรี",
                "กาฬสินธุ์",
                "กำแพงเพชร",
                "ขอนแก่น",
                "จันทบุรี",
                "ฉะเชิงเทรา",
                "ชลบุรี",
                "ชัยนาท",
                "ชัยภูมิ",
                "ชุมพร",
                "เชียงราย",
                "เชียงใหม่",
                "ตรัง",
                "ตราด",
                "ตาก",
                "นครนายก",
                "นครปฐม",
                "นครพนม",
                "นครราชสีมา",
                "นครศรีธรรมราช",
                "นครสวรรค์",
                "นนทบุรี",
                "นราธิวาส",
                "น่าน",
                "เบตง",
                "บึงกาฬ",
                "บุรีรัมย์",
                "ปทุมธานี",
                "ประจวบคีรีขันธ์",
                "ปราจีนบุรี",
                "ปัตตานี",
                "พระนครศรีอยุธยา",
                "พะเยา",
                "พังงา",
                "พัทลุง",
                "พิจิตร",
                "พิษณุโลก",
                "เพชรบุรี",
                "เพชรบูรณ์",
                "แพร่",
                "ภูเก็ต",
                "มหาสารคาม",
                "มุกดาหาร",
                "แม่ฮ่องสอน",
                "ยโสธร",
                "ยะลา",
                "ร้อยเอ็ด",
                "ระนอง",
                "ระยอง",
                "ราชบุรี",
                "ลพบุรี",
                "ลำปาง",
                "ลำพูน",
                "เลย",
                "ศรีสะเกษ",
                "สกลนคร",
                "สงขลา",
                "สตูล",
                "สมุทรปราการ",
                "สมุทรสงคราม",
                "สมุทรสาคร",
                "สระแก้ว",
                "สระบุรี",
                "สิงห์บุรี",
                "สุโขทัย",
                "สุพรรณบุรี",
                "สุราษฎร์ธานี",
                "สุรินทร์",
                "หนองคาย",
                "หนองบัวลำภู",
                "อ่างทอง",
                "อำนาจเจริญ",
                "อุดรธานี",
                "อุตรดิตถ์",
                "อุทัยธานี",
                "อุบลราชธานี"
            ],
        },
        loading: false,
        profile: {
            name: "",
            permission: {
                user_group: ""
            }
        },
        group_permissions: {},
        error: null,
    },
    getters: {
        isLoading(state) {
            return state.loading;
        },
        getError(state) {
            return state.error;
        },
    },
    mutations: {
        setLoading(state, data) {
            state.loading = data
        },

        setBranchList(state, data) {
            state.list.branchs = data
        },

        setProvincesList(state, data) {
            state.list.provinces = data
        },

        setSelectedBranch(state, val) {
            let listFiltered = state.list.branchs.filter((el) => {
                return el._id == val
            });
            //console.log("setSelectedBranch", listFiltered[0]);
            state.selected_branch._id = listFiltered[0]._id
            state.selected_branch.branch_name = listFiltered[0].branch_name
            state.selected_branch.phone_number = listFiltered[0].phone_number
        },

        setProfile(state, data) {
            state.profile = data
        },
        
        setGroupPermissions(state, data) {
            state.group_permissions = data
        },

        setError(state, error) {
            state.error = error;
        }
    },
    actions: {
        onReload({ commit }) { //ON RELOAD PAGE ONLY
            commit('setLoading', true);
        },
        getBranchs: async({ commit }) => {
            try {
                const response = await Vue.prototype.$axios.post(`${Vue.prototype.$baseUrl}/branch/get_user_branch`);
                const list = response.result;
                await commit('setBranchList', list);
                if (list.length) {
                    await commit('setSelectedBranch', list[0]._id);
                }
                commit('setLoading', false);
            } catch (err) {
                console.error(err);
                commit('setLoading', false);
                // Assuming you have an error mutation
                commit('setError', err.message || 'Failed to fetch branches');
            }
        },
        getProvinces: async({ commit, state }) => {
            await Vue.prototype.$axios.post(`${Vue.prototype.$baseUrl}/get_province`)
                .then(async(res) => {
                    let list = res.result;
                    await commit('setProvincesList', list)
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        getMyProfile: async ({ commit }) => {
            const customerToken = localStorage.getItem('access_token');
            const backendToken = localStorage.getItem('bo_access_token');
            const accessToken = customerToken || backendToken;
      
            if (!accessToken) {
              commit('setProfileError', 'No access token found');
              return;
            }
      
            const isBackend = !!backendToken;
            const cleanToken = accessToken.replace("Bearer ", "");
      
            try {
              const decoded = Vue.prototype.$jwt.decode(cleanToken, Vue.prototype.$privateKey);
              const body = {
                token: Vue.prototype.$jwt.sign(
                  { user_id: decoded.identity._id },
                  Vue.prototype.$privateKey,
                  { noTimestamp: true }
                ),
              };
      
              const url = `${Vue.prototype.$baseUrl}/${isBackend ? 'backend/' : ''}users/get_by_id`;
              const response = await Vue.prototype.$axios.post(url, body);
              await commit('setProfile', response.result);
            } catch (err) {
              console.error(err);
              commit('setProfileError', err.message);
            }
          },
        loadingComplete({ commit }) {
            commit('setLoading', false);
        },
        getGroupPermissions: async({ commit, state }) => {
            try {
                const body = {
                    token: Vue.prototype.$jwt.sign(
                      { user_group_id: state.profile.permission.user_group },
                      Vue.prototype.$privateKey,
                      { noTimestamp: true }
                    ),
                  };
                const url = `${Vue.prototype.$baseUrl}/user_group/get_by_id`;
                const response = await Vue.prototype.$axios.post(url, body);
                await commit('setGroupPermissions', response.result.permission);
            } catch (err) {
                console.error(err);
                commit('setGroupPermissions', err.message);
            }
        },
    },
    modules: {}
})