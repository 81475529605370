<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        :small="small"
        class="white--text"
        :color="isActive ? 'success' : 'error'"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
      >
        {{ isActive ? list[0].text : list[1].text }}
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-chip>
    </template>

    <v-list>
      <template v-for="item in list">
        <v-list-item :key="item.value" link dense v-if="item.value != status">
          <v-list-item-title @click="onSelected(item.value)">
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  model: {
    event: 'change'
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    path: {
      type: [String],
      default: false
    },
    path: {
      type: [String],
      default: false
    },
    params: {
      type: [Object],
      default: false
    }
  },

  data() {
    return {
      list: [
        { text: 'เปิดใช้งาน', value: true },
        { text: 'ปิดใช้งาน', value: false }
      ],
      status: this.value
    };
  },

  watch: {
    value: function(newVal, oldVal) {
      this.status = newVal;
    }
  },

  methods: {
    onSelected(status) {
      this.onSave(status);
    },
    onSave(status) {
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(this.params, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios
              .post(`${this.$baseUrl}/${this.path}`, body)
              .then(res => {
                if(res.status == 'main product can\'t changed status'){
                  this.$alertServerError({ title: res.status });
                } else {
                  this.$alertSuccess({ title: res.status });
                  this.status = status;
                  this.$emit('success', status);
                }
              })
              .catch(err => {
                console.log('err', err);
                this.$alertServerError({ title: err.error_message });
              });
            console.log('save()');
          }
        }
      );
    }
  },

  computed: {
    isActive() {
      return this.status == true;
    }
  }
};
</script>
